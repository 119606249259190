import React, { useEffect, useState } from "react";
import AutomationInput from "./AutomationInput";
import AutomationTextArea from "./AutomationTextArea";
import { ReactComponent as AiSparkle } from "assets/images/ai-sparkle.svg";
import { ReactComponent as AiSparkleDisabled } from "assets/images/ai-sparkle-disabled.svg";
import AutomationInputCheckbox from "./AutomationInputCheckbox";
import AutomationInputRadio from "components/AutomationInputRadio";
import AskSession, { AskSessionProps } from "components/AskSession";

export interface EventTypeMap {
  click: "CLICK";
  scrollend: "SCROLL";
  clickHold: "HOLD_CLICK";
  dblclick: "DOUBLE_CLICK";
  keyPress: "KEY_PRESS";
  typing: "TYPE";
  submit: "SUBMIT";
  resize: "RESIZE";
  textSelection: "TEXT_SELECT";
  contextmenu: "CLICK";
  keydown: "KEY_DOWN";
  tabChange: "TAB_CHANGE";
  input: "INPUT";
}

type InputField = {
  label: string;
  step_id: string;
  tag_name: string;
  event_type: EventTypeMap[keyof EventTypeMap];
  input_type: string | null;
};

interface InputValue {
  value_type: string;
  value: any;
}

type Props = {
  askSessionProps?: AskSessionProps;
  showAskSession?: boolean;
  onSubmit: (finalValues: any[]) => void;
  workflowMsg: string;
  inputs: any[];
  onCancel: () => void;
  config?: any;
  onRephrase?: (
    data: {
      step_id: string;
      label: string;
      values: InputValue[];
    }[]
  ) => void;
  rephraseResponse?: { [key: string]: string };
};
const isMultipleChoiceField = (input_type: string) => {
  return ["checkbox"].includes(input_type);
};
const AutomationInputForm = ({
  inputs,
  workflowMsg,
  onSubmit,
  onCancel,
  config,
  onRephrase,
  rephraseResponse,
  askSessionProps,
  showAskSession,
}: Props) => {
  const [formValues, setFormValues] = useState<{
    [key: string]: string | string[];
  }>({});
  console.log("inputs===>", inputs);
  // const inputFields = inputs.map((input) => {
  //   return input?.input_fields;
  // });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inputFields, setInputFields] = useState<any[]>();
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [genInProgress, setGenInProgress] = useState<boolean>(false);
  const handleSubmit = () => {
    if (validateForm()) {
      let finalValues: any = [];
      console.log("formValues", formValues);
      inputFields?.forEach((field) => {
        const step = {
          step_id: field?.step_id,
          values: isMultipleChoiceField(field?.input_type)
            ? (formValues?.[field?.step_id] as string[])?.map((item) => {
                return {
                  value_type: field?.input_type,
                  value: item,
                };
              })
            : [
                {
                  value_type: field?.input_type,
                  value: formValues?.[field?.step_id],
                },
              ],
        };
        finalValues = [...finalValues, step];
      });

      console.log("finalValues", finalValues);
      // for (const [key, value] of Object.entries(formValues)) {
      //   const stepId = key?.split(":")?.[1];
      //   const values = [{ value_type:  }];
      // }

      onSubmit(finalValues);
    }
  };

  const validateForm = () => {
    let valid = true;
    let newErrors = {};
    console.log("Object.entries(formValues)", Object.entries(formValues));
    for (const [key, value] of Object.entries(formValues)) {
      console.log("value, key", key, value);
      if (!value || (Array.isArray(value) && value.length === 0)) {
        newErrors = {
          ...newErrors,
          [key]: "Required field",
        };
        valid = false;
      }
    }
    if (Object.entries(formValues).length === 0 || !formValues) {
      inputFields?.forEach((input) => {
        newErrors = {
          ...newErrors,
          [input?.step_id]: "Required field",
        };
      });
      valid = false;
    }
    // if (!taskSummary?.trim()) {
    //   newErrors = {
    //     ...newErrors,
    //     taskSummary: "Required field",
    //   };
    //   valid = false;
    // }

    setErrors(newErrors);
    return valid;
  };

  const handleGenAIChange = (fieldName: string) => {
    try {
      setGenInProgress(true);
      let finalValues: any = [];
      inputFields?.forEach((field) => {
        const step = {
          step_id: field?.step_id,
          label: field?.label,
          values: [
            {
              value_type: field?.input_type,
              value: formValues?.[field?.step_id],
            },
          ],
        };
        finalValues = [...finalValues, step];
      });
      if (onRephrase) {
        onRephrase(finalValues);
      }
      //Api Call here
      // setFormValues({ ...formValues, [fieldName]: "value from server" });
    } catch (error) {}
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, id, value, type, checked } = e.target as HTMLInputElement;
    console.log(
      "name, id, value, type, checked",
      name,
      id,
      value,
      type,
      checked
    );
    if (type === "checkbox") {
      const existingValues: string[] =
        (formValues?.[e.target.name] as string[]) || [];
      if (checked) {
        existingValues?.push(id);
      } else {
        // Remove the id from the array if the checkbox is unchecked
        const index = existingValues.indexOf(id);
        if (index > -1) {
          existingValues.splice(index, 1);
        }
      }
      setFormValues({ ...formValues, [e.target.name]: existingValues });
    } else if (type === "radio") {
      setFormValues({
        ...formValues,
        [e.target.name]: checked ? id : "",
      });
    } else setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };
  console.log("FormValues===>", formValues);
  useEffect(() => {
    let allInputs: any[] = [];
    const preFilledformvalues: any = {};
    inputs?.forEach((input) => {
      if (input?.input_fields) {
        allInputs = [...allInputs, ...input?.input_fields];
      }
    });
    allInputs.forEach((InputField) => {
      if (InputField?.step_id) {
        preFilledformvalues[InputField?.step_id] = InputField?.detected_value;
      } else {
        preFilledformvalues[InputField?.step_id] = "";
      }
    });
    setFormValues({ ...formValues, ...preFilledformvalues });
    setInputFields(allInputs);
  }, [inputs]);

  useEffect(() => {
    if (rephraseResponse) {
      for (const [key, value] of Object.entries(rephraseResponse)) {
        setFormValues({ ...formValues, [key]: value });
      }
      setGenInProgress(false);
    }
  }, [rephraseResponse]);

  return (
    <>
      <div className="my-3 relative">
        {showAskSession && (
          <AskSession
            {...askSessionProps}
            backgroundColor={config?.brand_colour}
          />
        )}
        {inputFields?.map((InputField, index) => {
          return InputField?.ai_generated ? (
            <AutomationTextArea
              rows={6}
              value={formValues?.[InputField?.step_id]}
              onChange={handleChange}
              name={InputField?.step_id}
              error={errors?.[InputField?.step_id]}
              label={
                <div className="w-full flex flex-row items-center justify-between">
                  <div>{InputField?.label || ""}</div>
                  <div
                    className={`flex flex-row items-center gap-0.5 cursor-pointer ${
                      genInProgress ? "pointer-events-none" : ""
                    } ${
                      !formValues?.[InputField?.step_id]
                        ? "pointer-events-none text-['#8D9196']"
                        : ""
                    }`}
                    onClick={() => handleGenAIChange(InputField?.step_id)}
                  >
                    <div
                      className={`text-semibold text-sm font-semibold ${
                        !formValues?.[InputField?.step_id]
                          ? "text-[#8D9196]"
                          : "text-robylon-pink"
                      }`}
                    >
                      {genInProgress ? "Rephrasing...." : "Rephrase with AI"}
                    </div>
                    {!genInProgress && (
                      <div className="w-4 h-4">
                        {!!formValues?.[InputField?.step_id] && <AiSparkle />}
                        {!formValues?.[InputField?.step_id] && (
                          <AiSparkleDisabled />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              }
            />
          ) : (
            <>
              {(InputField?.input_type === "text" ||
                InputField?.input_type === "password" ||
                InputField?.input_type === "email") && (
                <AutomationInput
                  label={InputField?.label || ""}
                  type="text"
                  name={InputField?.step_id}
                  value={formValues?.[InputField?.step_id]}
                  error={errors?.[InputField?.step_id]}
                  onChange={handleChange}
                />
              )}
              {InputField?.input_type === "checkbox" && (
                <AutomationInputCheckbox
                  possbileValues={InputField?.possible_values}
                  label={InputField?.label || ""}
                  name={InputField?.step_id}
                  value={formValues?.[InputField?.step_id]}
                  error={errors?.[InputField?.step_id]}
                  onChange={handleChange}
                />
              )}
              {InputField?.input_type === "radio" && (
                <AutomationInputRadio
                  possbileValues={InputField?.possible_values}
                  label={InputField?.label || ""}
                  name={InputField?.step_id}
                  value={formValues?.[InputField?.step_id]}
                  error={errors?.[InputField?.step_id]}
                  onChange={handleChange}
                />
              )}
            </>
          );
        })}

        <div className="flex flex-row gap-2 w-full">
          <button
            style={{
              borderColor: config?.brand_colour || "",
              color: config?.brand_colour || "",
            }}
            className={`py-2 px-3 ${
              !config?.brand_colour ? "text-robylon-blue" : ""
            } bg-white border ${
              !config?.brand_colour ? "border-robylon-blue" : ""
            } font-semibold rounded-md ${
              isLoading && "opacity-50 cursor-not-allowed"
            }`}
            onClick={onCancel}
            disabled={isLoading}
          >
            Cancel
          </button>
          <button
            style={{ background: config?.brand_colour || "" }}
            onClick={handleSubmit}
            className={`py-2 px-3 text-white bg-robylon-blue rounded-md font-semibold ${
              isLoading && "opacity-50 cursor-not-allowed"
            }`}
            disabled={isLoading || genInProgress}
          >
            Execute
          </button>
        </div>
        {/* <AutomationInput label="Fee Structure Name" type="text" /> */}
      </div>
    </>
  );
};

export default AutomationInputForm;
