import React from "react";
import CloseImage from "assets/images/close-icon.svg";
import GearIcon from "assets/images/settings-gear.svg";
interface Props {
  onClick: () => void;
}

function SettingsButton({ onClick }: Props) {
  return (
    <img
      onClick={onClick}
      src={GearIcon}
      alt="Description"
      width={24}
      height={24}
      className="cursor-pointer mx-1"
    />
  );
}

export default SettingsButton;
