import React from "react";
import { ReactComponent as SessionCaptureIcon } from "assets/images/session-capture-icon.svg";
import { ReactComponent as SessionCapturedSuccess } from "assets/images/check-circle-fill.svg";

export interface AskSessionProps {
  backgroundColor?: string;
  success?: boolean;
  error?: boolean;
  loading?: boolean;
  onClick?: () => void;
  isAuthAvailable?: boolean;
}

const AskSession = ({
  backgroundColor,
  success,
  error,
  loading,
  onClick,
  isAuthAvailable,
}: AskSessionProps) => {
  return (
    <>
      {!isAuthAvailable && (
        <div className="w-full left-0 right-0 bottom-0 top-[184px] z-[200] absolute bg-white opacity-50"></div>
      )}
      <div className="w-full h-[184px]  pt-4 flex-col justify-start items-center gap-12 inline-flex">
        <div className="self-stretch h-40 p-4 bg-white rounded-lg shadow border border-gray-200 flex-col justify-center items-start gap-4 flex">
          <div className="self-stretch h-[68px] flex-col justify-center items-start gap-1 flex">
            <div className="self-stretch justify-start items-center gap-1 inline-flex">
              <div className="text-neutral-700 text-base font-semibold font-['Inter'] leading-normal">
                {isAuthAvailable ? "Auth for Automation" : "Need Auth Info"}
              </div>
            </div>
            <div className="self-stretch text-neutral-700 text-sm font-normal font-['Inter'] leading-tight">
              {isAuthAvailable
                ? "The copilot requires your login session info to run automations in the virtual browser"
                : "We require your login session info to run the automation in the virtual browser"}
            </div>
          </div>
          <button
            onClick={onClick}
            disabled={success || loading}
            className={`self-stretch px-6 py-2.5  rounded-md justify-center items-center gap-2 inline-flex ${
              !backgroundColor ? "bg-indigo-500" : ""
            }`}
            style={{ backgroundColor: backgroundColor }}
          >
            {!success && !loading && (
              <>
                {!!error && (
                  <div className="text-center text-red-500 text-sm font-normal font-['Inter'] leading-tight">
                    Something went wrong. Please try again.
                  </div>
                )}
                <div className="w-6 h-6 relative">
                  <SessionCaptureIcon />
                </div>
                <div className="text-white text-base font-semibold font-['Inter'] leading-[18px]">
                  {isAuthAvailable
                    ? "Reshare Login Session"
                    : "Share Login Session"}
                </div>
              </>
            )}
            {!!success && (
              <>
                <div className="w-6 h-6 relative">
                  <SessionCapturedSuccess />
                </div>
              </>
            )}
            {!success && loading && (
              <div role="status">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-400 animate-spin dark:text-gray-600 fill-white"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default AskSession;
