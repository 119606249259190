import React from "react";
import CloseImage from "assets/images/close-icon.svg";
interface Props {
  onClick: () => void;
}

function CloseButton({ onClick }: Props) {
  return (
    <img
      onClick={onClick}
      src={CloseImage}
      alt="Description"
      width={32}
      height={32}
      className="cursor-pointer mx-1"
    />
  );
}

export default CloseButton;
