import React from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link,
} from "react-router-dom";
import ChatbotUIClient from "components/ChatbotUIClient";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/chatbot"
          element={
            <ChatbotUIClient
              showRefresh={true}
              showClose={true}
              onClose={() => {
                // Post a message to the parent window
                window.parent.postMessage("closeChatbot", "*"); // Use a specific origin instead of '*' for better security
              }}
            />
          }
        />
      </Routes>
    </Router>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.tsx</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
