import React, { InputHTMLAttributes } from "react";

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string | React.ReactNode;
  error?: string;
  possbileValues?: any[];
}

const AutomationInputRadio = ({
  label,
  error,
  possbileValues,
  ...props
}: Props) => {
  return (
    <div className="my-4">
      <div className="mb-2">{label}</div>
      {possbileValues?.map((option) => (
        <div className="flex flex-row items-center gap-2" title={option?.value}>
          <input
            id={option?.rbylnId}
            value={option?.rbylnId}
            type={"radio"}
            className={`${
              !!error ? "border-robylon-error" : "border-robylon-gray-300"
            } text-capitalize`}
            {...props}
          />
          <label htmlFor={option?.rbylnId} className="cursor-pointer">
            {option?.value}
          </label>
        </div>
      ))}
      {!!error && (
        <div className="text-robylon-error text-sm px-1 py-1">{error}</div>
      )}
    </div>
  );
};

export default AutomationInputRadio;
