import React, { InputHTMLAttributes } from "react";

interface Props
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  label?: string | React.ReactNode;
  error?: string;
}

const AutomationTextArea = ({ label, error, ...props }: Props) => {
  return (
    <div className="my-4">
      <div className="mb-2">{label}</div>
      <textarea
        className={`w-full p-2 border border-solid focus:border-none focus-visible:border-none  ${
          !!error ? "border-robylon-error" : "border-robylon-gray-300"
        } rounded-md resize-none`}
        {...props}
      />
      {!!error && (
        <div className="text-robylon-error text-sm px-1 py-1">{error}</div>
      )}
    </div>
  );
};

export default AutomationTextArea;
