import RefreshImage from "assets/images/refresh-icon.svg";
import { useState } from "react";

interface Props {
  onClick: () => void;
  noRotate?: boolean;
}

function RefreshButton({ onClick, noRotate }: Props) {
  const [rotation, setRotation] = useState(0);

  const handleHover = () => {
    setRotation((prevRotation) => prevRotation + 180); // Add 180 degrees on each hover
  };

  const buttonStyle = {
    transform: `rotate(${rotation}deg)`,
    transition: "transform 0.5s ease",
  };

  return (
    <img
      onMouseEnter={handleHover} /* Add onMouseEnter event */
      onClick={onClick}
      src={RefreshImage}
      alt="Refresh"
      width={24}
      height={24}
      className="cursor-pointer mx-2.5"
      style={!noRotate ? buttonStyle : {}}
    />
  );
}

export default RefreshButton;
