import React, { useState } from "react";

interface DynamicInputDataToShowProps {
  data?: {
    text: string; // "We have captured the following data"
    values: Array<{
      value: string; // "text1" | "text2"
      ID: string; // "TEXT1" | "TEXT2"
      display_text: string; // "TEXT1"
    }>;
  };
}

const DynamicInputDataToShow: React.FC<DynamicInputDataToShowProps> = ({
  data,
}) => {
  return (
    <div className="p-2 w-[280px]">
      <div className={`border-b border-b-robylon-gray pt-1 pb-2 mb-2`}>
        {data?.text}
      </div>
      {!!data?.values?.length && (
        <ul>
          {data?.values?.map((value) => {
            return <li>{`${value?.display_text} : ${value?.value}`}</li>;
          })}
        </ul>
      )}
    </div>
  );
};

export default DynamicInputDataToShow;
