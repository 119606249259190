import React, { useState } from "react";
import CheckGreen from "assets/images/check-green.svg";
import Warning from "assets/images/warning-icon.svg";
import CaretRight from "assets/images/caret-right.svg";
import { InputPayload } from "components/ChatbotUIClient";

interface DynamicInputButtonsProps {
  config?: any;
  data?: {
    text: string; // "Please Click Continue to proceed"
    values: Array<{
      value: string; // "Continue" | "Cancel"
      ID: string; // "CONTINUE" | "CANCEL",
      primary_button: boolean;
    }>;
  };
  onClick: (params: { id: string; exec_id: string; input_id: string }) => void;
  exec_id?: string;
  input_id?: string;
  isCompleted: boolean;
}

const DynamicInputButtons: React.FC<DynamicInputButtonsProps> = ({
  data,
  onClick,
  exec_id,
  input_id,
  isCompleted,
  config,
}) => {
  console.log("Buttons", data?.values, config);
  return (
    <div className="p-2  w-[280px]">
      <div
        className={`pt-1 pb-2 mb-2 ${
          isCompleted ? "text-robylon-gray-dark" : ""
        }`}
      >
        {data?.text}
      </div>
      {!!data?.values?.length && (
        <div className="flex flex-row gap-1 align-center">
          {data?.values?.map((value) => {
            return (
              <button
                style={{
                  borderColor: !isCompleted ? config?.brand_colour : "",
                  color:
                    value?.primary_button && !isCompleted
                      ? "#ffffff"
                      : isCompleted
                      ? ""
                      : config?.brand_colour || "",
                  backgroundColor:
                    value?.primary_button && !isCompleted
                      ? config?.brand_colour
                      : "",
                }}
                disabled={isCompleted}
                className={`border ${
                  !config?.brand_colour
                    ? "text-robylon-blue border-robylon-blue "
                    : ""
                } ${
                  isCompleted ? "border-robylon-gray text-robylon-gray" : ""
                } border-1 rounded px-2 py-0.5   font-semibold flex flex-row  items-center gap-1`}
                onClick={() => {
                  if (onClick && exec_id && input_id)
                    onClick({ id: value?.ID, exec_id, input_id });
                }}
              >{`${value?.value}`}</button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DynamicInputButtons;
