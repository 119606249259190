/**
 * ChatbotUI Component
 *
 * This component renders a chat UI using @chatscope/chat-ui-kit-react. It features a main chat container,
 * an input area for sending messages, and a suggestion bar with predefined queries.
 *
 * The chat interface includes an avatar, a header with the chatbot's name and status,
 * and a message list displaying both incoming and outgoing messages.
 *
 * The component maintains its own state for messages and the input value, and allows users
 * to send new messages either by typing or by clicking on one of the suggestion buttons.
 */

import React, { useEffect, useRef, useState } from "react";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationHeader,
  Loader,
} from "@chatscope/chat-ui-kit-react";
// import CloseButton from "components/CloseButton";
// import RefreshButton from "components/RefreshButton";
// import { Button } from "react-bootstrap";
import {
  Organization,
  OrganizationConfig,
  User,
  conversation_status,
  getOrgListApi,
  getUserFromApi,
} from "apiService";
import { useLocation, useParams } from "react-router-dom";
import SendMessageIcon from "assets/images/send-message-icon.svg";
import SendMessageJiraIcon from "assets/images/send-message-icon-jira.svg";
import SendMessageAirmeetIcon from "assets/images/send-message-icon-airmeet.svg";
import SendMessageTeachmintIcon from "assets/images/send-message-icon-teachmint.svg";
import SendMessageFareyeIcon from "assets/images/send-message-icon-fareye.svg";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { generateRandomString } from "utils/Misc";
import AutomationInputForm from "./AutomationInputForm";
import ExecutionState from "components/ExectutionState";
import {
  jiraConfig,
  airmeetConfig,
  teachmintConfig,
  fareyeConfig,
} from "utils/Config";
import CloseButton from "components/CloseButton";
import RefreshButton from "components/RefreshButton";
import AskSessionStandalone from "components/AskSessionStandalone";
import SettingsButton from "components/SettingsButton";
import SettingsPage from "components/SettingsPage";
import DynamicInputButtons from "components/DynamicInputButtons";
import DynamicInputDataToShow from "components/DynamicInputDataToShow";

type WorkflowStatus = "LOADING" | "SUCCESS" | "FAILED";

enum DynamicInputType {
  BUTTON = "BUTTON",
  DATA = "DATA",
}

// IMessage defines the structure for messages in the chat.
interface IMessage {
  text: string;
  direction: "incoming" | "outgoing";
  loading?: boolean; // Indicates if this message is a placeholder for loading
  isForm?: boolean;
  inputs?: any;
  contextId?: string;
  status?: WorkflowStatusEnum;
  workflow?: { workflow: Workflow };
  video_url?: string;
  input_payload?: InputPayload;
  input_id?: string;
  exec_id?: string;
  isDynamicContent?: boolean;
  dynamicInputType?: DynamicInputType;
  m_id?: string;
  isDisabled?: boolean;
}
interface Props {
  showClose?: boolean;
  showRefresh?: boolean;
  onClose?: () => void;
  isStandAlone?: boolean;
}

enum WorkflowStatusEnum {
  NOT_RUNNING = "NOT_RUNNING",
  PREPARING = "PREPARING",
  RUNNING = "RUNNING",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  CANCELLED = "CANCELLED",
}

export interface Workflow {
  workflow_id: string;
  workflow_data: object;
  title: string | null;
  description: string | null;
  input_fields: any | null; // Use a more specific type if possible
  requires_inputs: boolean | null;
  published: boolean;
  created_at: string;
  updated_at: string;
  last_run_at: string | null;
  apps: any[];
  user: string;
  is_auth_complete?: boolean;
}

export interface InputPayload {
  button: {
    text: string; // "Please Click Continue to proceed"
    values: Array<{
      value: string; // "Continue" | "Cancel"
      ID: string; // "CONTINUE" | "CANCEL"
      primary_button: boolean;
    }>;
  };
  data_to_show: {
    text: string; // "We have captured the following data"
    values: Array<{
      value: string; // "text1" | "text2"
      ID: string; // "TEXT1" | "TEXT2"
      display_text: string; // "TEXT1"
    }>;
  };
  input_form: {
    text: string; // "Please input the following information to continue"
    values: Array<{
      value: string; // "text1" | "text2"
      ID: string; // "TEXT1" | "TEXT2"
      type: string; // "TEXT"
    }>;
  };
}

interface WebSocketMessage {
  m_id?: string;
  chat_id: string;
  user_id?: string;
  input_id?: string;
  event_type: string;
  message?: string;
  error?: string;
  inputs: any;
  result?: any;
  context_id?: string;
  workflow?: { workflow: Workflow };
  status?: WorkflowStatusEnum;
  video_url?: string;
  generated_content?: { [key: string]: string };
  exec_id?: string;
  live_stream_url?: string;
  end_state_info?: {
    url: string;
    button_name: string;
  };
  input_payload?: InputPayload;
  text_to_display?: string;
}

interface SendMessageParams {
  event_type?: string;
  message?: string;
  user_inputs?: any[];
  m_id?: string;
  chat_id?: string;
  user_id?: string;
  session_info?: any;
  cookies_info?: any;
  storage_info?: any;
  current_url?: string;
  org_id?: string | null;
  domain_name?: string | null;
}

const mockMessages = [
  {
    text: "Hello! How can I assist you today?",
    direction: "incoming",
    loading: false,
  },
  // {
  //   text: 'I have a question about my account.',
  //   direction: 'outgoing',
  //   loading: false
  // },
  // {
  //   text: 'Sure, I can help with that. Could you please provide me with your account number?',
  //   direction: 'incoming',
  //   loading: false
  // },
  // {
  //   text: "It's 123456789.",
  //   direction: 'outgoing',
  //   loading: false
  // },
  // {
  //   text: 'Thank you! Let me look that up for you.',
  //   direction: 'incoming',
  //   loading: false // Simulate the bot taking a moment to respond
  // },
  // // Example of a message that could trigger a contact form display
  // {
  //   text: 'To better assist you, please fill out this quick form.',
  //   direction: 'incoming',
  //   isForm: false // Assuming this triggers a form in your UI
  // }
];

const idToConfigMap: any = {
  jira: jiraConfig,
  airmeet: airmeetConfig,
  teachmint: teachmintConfig,
  fareye: fareyeConfig,
};

const idToIconMap: any = {
  jira: SendMessageJiraIcon,
  airmeet: SendMessageAirmeetIcon,
  teachmint: SendMessageTeachmintIcon,
  fareye: SendMessageFareyeIcon,
};

const fareyeuserId = "4c928499-1549-416e-8f45-3ef18c94a82d";

const setUserId = (user_id: string, isFarEye: boolean) => {
  localStorage?.setItem("user_id", isFarEye ? fareyeuserId : user_id);
};

interface CapturedData {
  localStorage: string;
  sessionStorage: string;
  cookies: chrome.cookies.Cookie[];
  url: string;
  host: string;
}

const ChatbotUIClient: React.FC<Props> = ({
  showClose,
  showRefresh,
  onClose,
  isStandAlone,
}: Props) => {
  const [messages, setMessages] = useState<IMessage[]>([]);
  const messagesRef = useRef(messages);
  console.log("MEESSS", messages);
  const [inputValue, setInputValue] = useState<string>("");
  const [conversationId, setConversationId] = useState<string | null>("10");
  const [botTyping, setBotTyping] = useState<boolean>(false);
  const location = useLocation();
  // const [config, setConfig] = useState<any>();
  console.log("document.referrer", document);
  const [parentDomain, setParentDomain] = useState<string>(
    document.referrer ? new URL(document.referrer).hostname : ""
  );
  const [showContactForm, setShowContactForm] = useState<boolean>(false);
  const [popupOpened, setPopupOpened] = useState<boolean>(false);
  const [websocket, setWebsocket] = useState<WebSocket | null>(null);
  const [showInputForm, setShowInputForm] = useState<boolean>(false);
  const [inputMessage, setInputMessage] = useState<IMessage>();
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isFormFailed, setIsFormFailed] = useState(false);
  const [isFormSuccess, setIsFormSuccess] = useState(false);
  const [workflowContextId, setWorkflowContextId] = useState("");
  const [workflowStatusMap, setWorkflowStatusMap] = useState<
    Record<string, Record<string, any>>
  >({});
  const [execId, setExecId] = useState<string>();

  const [workflowVideoMap, setWorkflowVideoMap] = useState<
    Record<string, Record<string, any>>
  >({});
  const [workflowStreamMap, setWorkflowStreamMap] = useState<
    Record<string, Record<string, any>>
  >({});
  const [endStateInfo, setEndStateInfo] = useState<any>({});
  const [rephrasedValues, setRephrasedValues] = useState<{
    [key: string]: string;
  }>();
  console.log("workflowStatusMap", workflowStatusMap);
  // Parse the query parameters
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const extensionId = queryParams.get("extensionId");
  // const config = id ? idToConfigMap[id] : {};
  const [sessionData, setSessionData] = useState<any>();
  const [sessionDataLoading, setSessionDataLoading] = useState<boolean>(false);
  const [sessionDataSuccess, setSessionDataSuccess] = useState<boolean>(false);
  const [sessionDataError, setSessionDataError] = useState<boolean>(false);
  const [config, setConfig] = useState<Organization>();
  const [authComplete, setAuthcomplete] = useState<boolean>(false);
  const [user, setUser] = useState<User>();
  const [openAuthScreen, setOpenAuthScreen] = useState<boolean>(false);
  const [openSettingsScreen, setOpenSettingsScreen] = useState<boolean>(false);
  const [completedInputIds, setCompletedInputIds] = useState<string[]>([]);
  // const [lastSaveIndex, setLastSavedIndex] = useState<any>();
  const lastSaveIndexRef = useRef<any>();

  const captureData = async () => {
    setSessionDataLoading(true);
    try {
      const params = new URLSearchParams(window.location.search);
      console.log("captureData extensionId", params.get("extensionId"));
      // const extensionId = params.get("extensionId");

      if (!extensionId) {
        throw new Error("Extension ID not found");
      }

      const response = await new Promise<CapturedData>((resolve, reject) => {
        chrome.runtime.sendMessage(
          extensionId,
          { action: "captureSessionData" },
          (response) => {
            if (chrome.runtime.lastError || response.error) {
              reject(chrome.runtime.lastError || response.error);
            } else {
              resolve(response);
            }
          }
        );
      });

      console.log("Data received from content script:", response);

      setSessionData(response);
      if (response) {
        const localstorage = response?.localStorage
          ? JSON.parse(response?.localStorage)
          : {};
        const sessionStorage = response?.sessionStorage
          ? JSON.parse(response?.sessionStorage)
          : {};
        const url = response?.url || null;
        console.log(
          "sessionStorage, localstorage",
          localstorage,
          sessionStorage
        );
        const extraParams = {
          session_info: sessionStorage,
          storage_info: localstorage,
          cookies_info: response?.cookies,
          current_url: url || undefined,
          domain_name: response?.host,
        };
        const basePayload = getBasePayload("capture_session");
        const payload = { ...basePayload, ...extraParams };
        sendMessageToWs(payload);
        setSessionDataSuccess(true);
      }
    } catch (error) {
      console.error("Error capturing data or sending to backend:", error);
      setSessionDataError(true);
    }
    setSessionDataLoading(false);
  };

  const getAuthToken = async () => {
    return new Promise<string | undefined>((resolve, reject) =>
      chrome.runtime.sendMessage(
        extensionId,
        { action: "getAuthToken" },
        (response) => {
          console.log("Response from getAuthToken", response);
          if (chrome.runtime.lastError || response.error) {
            reject(undefined);
          } else {
            console.log("getAuthToken response", response);
            resolve(response?.token);
          }
        }
      )
    );
  };
  const resetSessionVariables = () => {
    setSessionDataSuccess(false);
    setSessionDataError(false);
    setOpenAuthScreen(false);
  };

  useEffect(() => {
    console.log("useEffect Extension id", extensionId, id);
    if (extensionId) {
      getAuthToken();
    }
    if (extensionId && id) {
      getUserAndCompanies();
    }
  }, [extensionId, id]);

  useEffect(() => {}, []);

  const getUserAndCompanies = async () => {
    try {
      const token = await getAuthToken();
      if (token) {
        const resultsUserAndOrg = await Promise.all([
          getUserFromApi(token),
          getOrgListApi(token),
        ]);
        console.log("resultsUserAndOrg", resultsUserAndOrg);
        const user = resultsUserAndOrg[0]?.data?.result;
        const companies = resultsUserAndOrg[1]?.data?.result;
        const currentConfig = companies?.find((org) => id === org?.org_id);
        console.log("currentConfig", currentConfig?.config);
        setUser(user);
        setConfig(currentConfig);
      }
    } catch (error) {}
  };

  const sendDynamicInputMessage = ({
    id,
    exec_id,
    input_id,
  }: {
    id: string;
    exec_id: string;
    input_id: string;
  }) => {
    const basePayload = getBasePayload("dynamic_input");
    const outputId = {
      output_payload: {
        button: {
          ID: id,
        },
      },
    };
    const payload = { ...basePayload, ...outputId, exec_id, input_id };
    sendMessageToWs(payload);
  };

  /**
   * Sends a message.
   * Adds a new message to the messages state, and resets the input field.
   *
   *
   * @param {string} text - The message text to send.
   */

  const sendMessage = async (text: string): Promise<void> => {
    if (text.trim() === "" || !conversationId || botTyping) return;
    setBotTyping(true);
    const newOutMessage: IMessage = { text, direction: "outgoing" };
    const loadingMessage: IMessage = {
      text: "Loading...",
      direction: "incoming",
      loading: true,
    };

    if (websocket && user && id) {
      let user_id = user?.user_id || localStorage?.getItem("user_id") || "";
      let m_id = generateRandomString(8);
      let chatId = sessionStorage.getItem("rbyln-chatId");
      setUserId(user_id, false);

      sendMessageToWs({
        org_id: id,
        m_id: m_id,
        user_id: user_id,
        event_type: "user_m",
        chat_id: chatId || "",
        message: text,
        // input: { task_name: e }
      });
    }

    setMessages((prevMessages) => [
      ...prevMessages,
      newOutMessage,
      // loadingMessage,
    ]);
    setInputValue("");
    return;
  };

  const findAndUpdateWorkflowStatus = (
    contextId: string,
    status: WorkflowStatusEnum
  ) => {
    // const updatedMessages = messages.reduce(
    //   (acc: IMessage[], cur: IMessage) => {
    //     if (cur.contextId === contextId) {
    //       return [...acc, { ...cur, status }];
    //     }
    //     return [...acc, cur];
    //   },
    //   []
    // );
    // console.log({ updatedMessages });
    // setMessages(updatedMessages);

    setWorkflowStatusMap((prev) => {
      return { ...prev, [contextId]: { status } };
    });
  };

  const findAndUpdateWorkflowVideo = (contextId: string, video_url: string) => {
    // const updatedMessages = messages.reduce(
    //   (acc: IMessage[], cur: IMessage) => {
    //     if (cur.contextId === contextId) {
    //       return [...acc, { ...cur, status }];
    //     }
    //     return [...acc, cur];
    //   },
    //   []
    // );
    // console.log({ updatedMessages });
    // setMessages(updatedMessages);

    setWorkflowVideoMap((prev) => {
      return { ...prev, [contextId]: { video_url: video_url } };
    });
  };

  const findAndUpdateWorkflowStream = (
    contextId: string,
    live_stream_url: string
  ) => {
    // const updatedMessages = messages.reduce(
    //   (acc: IMessage[], cur: IMessage) => {
    //     if (cur.contextId === contextId) {
    //       return [...acc, { ...cur, status }];
    //     }
    //     return [...acc, cur];
    //   },
    //   []
    // );
    // console.log({ updatedMessages });
    // setMessages(updatedMessages);

    setWorkflowStreamMap((prev) => {
      return { ...prev, [contextId]: { live_stream_url: live_stream_url } };
    });
  };

  const findAndUpdateEndStateInfo = (contextId: string, info: any) => {
    // const updatedMessages = messages.reduce(
    //   (acc: IMessage[], cur: IMessage) => {
    //     if (cur.contextId === contextId) {
    //       return [...acc, { ...cur, status }];
    //     }
    //     return [...acc, cur];
    //   },
    //   []
    // );
    // console.log({ updatedMessages });
    // setMessages(updatedMessages);

    setEndStateInfo((prev: any) => {
      return { ...prev, [contextId]: { ...info } };
    });
  };

  // const startConversation = async () => {
  //   if (!id) return;
  //   try {
  //     console.log("parentDomain", parentDomain);
  //     const response = await startConverstaionApi(
  //       id,
  //       ConversationSources?.WIDGET_OR_IFRAME,
  //       parentDomain
  //     );
  //     if (response?.data?.result) {
  //       setConversationId(response?.data?.result?.conversation_id);
  //       if (config?.chat_interface_config?.welcome_message) {
  //         const welcomeText: IMessage = {
  //           text: config?.chat_interface_config?.welcome_message,
  //           direction: "incoming",
  //         };
  //         setMessages([welcomeText]);
  //       }
  //     }
  //   } catch (error) {}
  // };

  /**
   * Handles click on a suggestion button.
   * Utilizes the sendMessage function to send predefined suggestions.
   *
   * @param {string} suggestion - The predefined suggestion text.
   */
  const handleSuggestionClick = (suggestion: string): void => {
    sendMessage(suggestion);
  };

  function removeLoadingObjects(array: IMessage[]) {
    // Use the filter method to return a new array
    // Exclude objects that have a loading property equal to true
    return array.filter((item) => !item.loading && !item?.isForm);
  }

  const handleMessage = (message: WebSocketMessage) => {
    setBotTyping(false);
    switch (message.event_type) {
      case "init":
        console.log("Chat initialized:", message);
        const chatId = message.chat_id;
        sessionStorage.setItem("rbyln-chatId", chatId);
        if (message.message) {
          const serverMessage: IMessage = {
            text: message.message,
            direction: "incoming",
          };
          setMessages((prevMessages) => [...prevMessages, serverMessage]);
        }
        console.log("Server message:", message);
        break;
      case "user_m":
        console.log("User message:", message);
        break;
      case "server_m":
        if (message.message) {
          const serverMessage: IMessage = {
            text: message.message,
            direction: "incoming",
          };
          setMessages((prevMessages) => [...prevMessages, serverMessage]);
        }
        console.log("Server message:", message);
        break;
      case "workflow_not_found":
        if (message.message) {
          const serverMessage: IMessage = {
            text: message.message,
            direction: "incoming",
          };
          setMessages((prevMessages) => [...prevMessages, serverMessage]);
        }
        console.error("Error:", message.error);
        break;
      case "workflow_found":
        if (message) {
          const serverMessage: IMessage = {
            text: "" || "",
            m_id: message?.m_id || "",
            direction: "incoming",
            isForm: true,
            inputs: message.inputs?.inputs,
            contextId: message?.context_id,
            workflow: message?.workflow,
          };
          setMessages((prevMessages) => [...prevMessages, serverMessage]);
          setWorkflowContextId(message.context_id || "");
          if (message?.workflow?.workflow?.is_auth_complete)
            setAuthcomplete(true);
          else setAuthcomplete(false);
        }
        console.error("Error:", message.error);
        break;
      case "capture_session_complete":
        setTimeout(() => {
          setAuthcomplete(true);
          // if (openAuthScreen) {
          //   setOpenAuthScreen(false);
          //   onRun(messages[messages?.length - 1], true);
          // }
        }, 2000);
        break;
      case "u_input":
        if (message) {
          const serverMessage: IMessage = {
            m_id: message?.m_id || "",
            text: message?.message || "",
            direction: "incoming",
            isForm: true,
            inputs: message.inputs?.inputs,
            contextId: message.context_id,
          };
          // setWorkflowContextId(message.context_id || "");
          // setMessages((prevMessages) => [...prevMessages, serverMessage]);
          setInputMessage(serverMessage);
        }

        console.log("User input request:", message, message.inputs?.inputs);
        break;
      case "execute":
        setBotTyping(true);

        console.log("Execute command:", message);
        break;

      case "generate_content":
        if (message && message?.generated_content) {
          setRephrasedValues(message?.generated_content);
        }
        break;

      case "wf_exec_log":
        if (message?.exec_id) {
          setExecId(message?.exec_id);
        }
        if (message.context_id && message?.status) {
          findAndUpdateWorkflowStatus(message.context_id, message?.status);
        }
        if (message.context_id && message?.video_url) {
          findAndUpdateWorkflowVideo(message.context_id, message?.video_url);
        }
        //Change this for stream
        if (message.context_id && message?.live_stream_url) {
          findAndUpdateWorkflowStream(
            message.context_id,
            message?.live_stream_url
          );
        }

        if (message.context_id && message?.end_state_info) {
          findAndUpdateEndStateInfo(
            message.context_id,
            message?.end_state_info
          );
        }
        setBotTyping(false);
        console.log("Execution result:", message);
        setInputMessage(undefined);
        setRephrasedValues(undefined);
        break;
      // case "execution_errored":
      //   findAndUpdateWorkflowStatus(message.context_id || "", message?.status);
      //   setBotTyping(false);
      //   console.log("Execution result:", message.result);
      //   break;
      case "dynamic_input":
        lastSaveIndexRef.current = {
          ...messagesRef?.current?.[messagesRef?.current?.length - 1],
        };
        // setLastSavedIndex(messages?.[messages?.length - 1]);
        const serverMessage1: IMessage = {
          text: message?.message || "",
          direction: "incoming",
          input_id: message?.input_id,
          exec_id: message?.exec_id,
          input_payload: message.input_payload,
          contextId: message?.context_id,
          workflow: message?.workflow,
          isDynamicContent: true,
          dynamicInputType: DynamicInputType?.DATA,
        };
        const serverMessage2: IMessage = {
          text: message?.message || "",
          direction: "incoming",
          input_id: message?.input_id,
          exec_id: message?.exec_id,
          input_payload: message.input_payload,
          contextId: message?.context_id,
          workflow: message?.workflow,
          isDynamicContent: true,
          dynamicInputType: DynamicInputType?.BUTTON,
        };
        setMessages((prevMessages) => [
          ...prevMessages,
          serverMessage1,
          serverMessage2,
        ]);
        break;
      case "dynamic_input_complete":
        if (message && message?.input_id) {
          setCompletedInputIds([...completedInputIds, message?.input_id]);
          // const serverMessage: IMessage = {
          //   text: message?.text_to_display || "",
          //   direction: "incoming",
          //   input_id: message?.input_id,
          //   exec_id: message?.exec_id,
          //   input_payload: message.input_payload,
          //   contextId: message?.context_id,
          //   workflow: message?.workflow,
          //   isDynamicContent: false,
          // };

          setMessages((prevMessages) => {
            const foundIndex = prevMessages?.findIndex(
              (message) => message?.m_id === lastSaveIndexRef?.current?.m_id
            );
            console.log("foundIndex====>", prevMessages, foundIndex);
            if (foundIndex > -1) {
              prevMessages[foundIndex].isDisabled = true;
              lastSaveIndexRef.current.text = message?.text_to_display || "";
              // prevMessages.splice(foundIndex, 1);
            }
            return [...prevMessages, lastSaveIndexRef?.current];
          });
        }
        break;
      default:
        console.log("Unhandled message type:", message.event_type);
    }
  };

  console.log("BotTyping", botTyping);

  const clearChat = () => {
    localStorage?.removeItem("cnvId");
    if (conversationId) localStorage?.removeItem(conversationId);
    setMessages([]);
    websocket?.close();
    startNewWs();
    // startConversation();
  };

  useEffect(() => {
    const handleMessage = (event: any) => {
      // Perform your checks here to ensure security, such as verifying event.origin
      if (event?.data?.name === "openFrame") {
        console.log("Received message:", event.data);
        // Process the message data as needed
        setPopupOpened(true);
      }
    };

    window.addEventListener("message", handleMessage, false);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("message", handleMessage, false);
    };
  }, []);

  useEffect(() => {
    setIsFormFailed(false);
    setIsFormLoading(false);
    setIsFormSuccess(false);
  }, [workflowContextId]);

  const showForm = (conversationStatus: conversation_status) => {
    if (conversationStatus === conversation_status?.LEAD_PENDING) {
      setTimeout(() => {
        setShowContactForm(true);
      }, 1000);
    } else {
      setShowContactForm(false);
    }
  };

  useEffect(() => {
    if (authComplete) {
      if (openAuthScreen) {
        setOpenAuthScreen(false);
        resetSessionVariables();
        onRun(messages[messages.length - 1], true);
      }
    }
  }, [authComplete, messages, openAuthScreen]);

  useEffect(() => {
    if (!openSettingsScreen) resetSessionVariables();
  }, [openSettingsScreen]);

  useEffect(() => {
    if (!showInputForm) resetSessionVariables();
  }, [!showInputForm]);

  // useEffect(() => {
  //   console.log("parentDomain", parentDomain);
  //   if (parentDomain || isStandAlone) {
  //     console.log("Parent domain");
  //     const oldConvId = localStorage?.getItem("cnvId");
  //     if (oldConvId) {
  //       setConversationId(oldConvId);
  //       const oldMessages = localStorage?.getItem(oldConvId);
  //       if (oldMessages) {
  //         setMessages(JSON.parse(oldMessages));
  //       }
  //     } else {
  //       if (config) {
  //         console.log("config", config);
  //         if (popupOpened || isStandAlone) {
  //           startConversation();
  //         }
  //       }
  //     }
  //   }

  //   return () => {};
  // }, [parentDomain, config, popupOpened, isStandAlone]);

  // useEffect(() => {
  //   if (conversationId) {
  //     const oldMessages = localStorage?.getItem(id);
  //     if (oldMessages) {
  //       setMessages(JSON.parse(oldMessages));
  //     }
  //   }
  // }, [conversationId]);

  const getBasePayload = (event_name: string) => {
    if (websocket && user) {
      let user_id = user?.user_id || localStorage?.getItem("user_id") || "";
      let m_id = generateRandomString(8);
      let chatId = sessionStorage.getItem("rbyln-chatId");
      setUserId(user_id, false);
      setBotTyping(true);
      return {
        org_id: id,
        m_id: m_id,
        user_id: user_id,
        event_type: event_name,
        chat_id: chatId || "",
      };
    }
  };

  const sendMessageToWs = (params: SendMessageParams) => {
    if (websocket && websocket.readyState === WebSocket.OPEN) {
      try {
        websocket.send(JSON.stringify(params));
      } catch (err) {
        console.log("Error ", err);
      }
    } else {
      console.error("WebSocket is not open. Cannot send message.");
    }
  };

  useEffect(() => {
    if (conversationId && messages?.length > 0) {
      localStorage?.setItem(
        conversationId,
        JSON.stringify(removeLoadingObjects(messages))
      );
      localStorage?.setItem("cnvId", conversationId);
    }
  }, [messages, conversationId]);

  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  useEffect(() => {
    if (showContactForm) {
      const showFormMessage: IMessage = {
        text: "Contact Form",
        direction: "incoming",
        isForm: true,
      };
      setMessages((prevMessages) => [...prevMessages, showFormMessage]);
    } else {
      setMessages((prevMessages) => [
        ...prevMessages.filter((message) => !message?.isForm),
      ]);
    }
  }, [showContactForm]);

  const startNewWs = () => {
    let ws: WebSocket | null = null;
    const sokcetUrl = process.env.REACT_APP_WEB_SOCKET_URL || "";
    ws = new WebSocket(sokcetUrl);

    ws.onopen = () => {
      console.log("WebSocket connection established");
      if (ws && user) {
        let user_id = user?.user_id || localStorage?.getItem("user_id") || "";
        let m_id = generateRandomString(8);
        let chatId = sessionStorage.getItem("rbyln-chatId");
        setUserId(user_id, false);

        ws.send(
          JSON.stringify({
            m_id: m_id,
            user_id: user_id,
            event_type: "init",
          })
        );
      }
    };

    ws.onerror = (error) => {
      console.error("WebSocket error", error);
    };

    ws.onclose = (e) => {
      console.log("WebSocket connection closed", e);
    };

    ws.onmessage = (event) => {
      const message: WebSocketMessage = JSON.parse(event.data);
      handleMessage(message);
    };

    setWebsocket(ws);
    // } else {
    //   if (websocket) {
    //     websocket.close();
    //   }
    // }

    return () => {
      if (ws) {
        ws.close();
      }
    };
  };

  useEffect(() => {
    if (user && config) startNewWs();
  }, [user, config]);

  useEffect(() => {
    const checkConnection = setInterval(() => {
      if (websocket && websocket.readyState !== WebSocket.OPEN) {
        // alert("Connection interrupted. Please check your internet connection.");
        console.error("Connection is not open");
      }
    }, 5000);

    return () => clearInterval(checkConnection);
  }, [websocket]);

  const onRun = (message: IMessage, skipAuth?: boolean) => {
    console.log("onRun called", message, skipAuth);
    if (!message?.workflow?.workflow?.requires_inputs) {
      if (!message?.workflow?.workflow?.is_auth_complete && !skipAuth) {
        setOpenAuthScreen(true);
        return;
      }
      // setOpenAuthScreen(false);
      if (websocket && user && id) {
        let user_id = user?.user_id || localStorage?.getItem("user_id") || "";
        let m_id = generateRandomString(8);
        let chatId = sessionStorage.getItem("rbyln-chatId");
        setUserId(user_id, false);
        setBotTyping(true);

        findAndUpdateWorkflowStatus(
          inputMessage?.contextId || "",
          WorkflowStatusEnum?.PREPARING
        );

        sendMessageToWs({
          org_id: id,
          m_id: m_id,
          user_id: user_id,
          event_type: "execute",
          chat_id: chatId || "",
        });
        setShowInputForm(false);
      }
      return;
    }
    setShowInputForm(true);
  };

  console.log({ workflowStatusMap });
  console.log("inputMessage?.inputs", inputMessage?.inputs, inputMessage);
  console.log("config", config);
  return (
    <div style={{ position: "relative", height: "100vh" }}>
      <MainContainer style={{ margin: "0 auto" }} className="w-[400px]">
        <ChatContainer>
          <ConversationHeader>
            {/* <Avatar src={config?.profile_picture || "/chatbubble.png"} /> */}
            <ConversationHeader.Content
              userName={config?.config?.display_name || "Robylon Bot"}
            />
            <ConversationHeader.Actions>
              {showRefresh && <RefreshButton onClick={clearChat} />}
              <SettingsButton
                onClick={() => {
                  setOpenSettingsScreen(true);
                }}
              />
              {showClose && onClose && (
                <CloseButton
                  onClick={() => {
                    if (openSettingsScreen) {
                      setOpenSettingsScreen(false);
                      return;
                    }
                    websocket?.close();
                    onClose();
                  }}
                />
              )}
              {/* <IconButton>
                <MoreVerticalIcon />
              </IconButton> */}
            </ConversationHeader.Actions>
          </ConversationHeader>
          <MessageList
            style={{ paddingTop: "20px" }}
            // loading={isTyping}
          >
            {messages?.map((message, index) => (
              <Message
                bubbleStyle={
                  message.direction === "outgoing" &&
                  config?.config?.brand_colour
                    ? {
                        backgroundColor: config?.config?.brand_colour,
                      }
                    : message?.isForm
                    ? {
                        padding: 0,
                        background: "transparent",
                        borderRadius: "8px",
                      }
                    : {}
                }
                model={{
                  sentTime: "just now",
                  sender: message.direction === "incoming" ? "Other" : "You",
                  direction: message.direction, // Use 'incoming' or 'outgoing'
                  position: "single",
                }}
                key={index}
              >
                <Message.CustomContent>
                  {/* <input type="email"></input> */}
                  {message.loading ? (
                    <Loader />
                  ) : !message?.isForm ? (
                    <span className="fs-6">{message.text}</span>
                  ) : null}
                  {message?.isForm && (
                    <ExecutionState
                      isDisabled={message?.isDisabled}
                      config={config?.config}
                      end_state_info={endStateInfo?.[message?.contextId || ""]}
                      streamLink={
                        workflowStreamMap?.[message?.contextId || ""]
                          ?.live_stream_url || undefined
                      }
                      videoLink={
                        workflowVideoMap?.[message?.contextId || ""]?.video_url
                      }
                      onCancel={() => {
                        findAndUpdateWorkflowStatus(
                          message?.contextId || "",
                          WorkflowStatusEnum?.CANCELLED
                        );
                      }}
                      isCancelled={
                        workflowStatusMap?.[message.contextId || ""]?.status ===
                        WorkflowStatusEnum.CANCELLED
                      }
                      onRun={() => {
                        onRun(message);
                      }}
                      workflowMsg={
                        message?.text ||
                        message?.workflow?.workflow?.title ||
                        ""
                      }
                      isError={
                        workflowStatusMap?.[message.contextId || ""]?.status ===
                        WorkflowStatusEnum.FAILED
                      }
                      isLoading={
                        workflowStatusMap?.[message.contextId || ""]?.status ===
                          WorkflowStatusEnum.PREPARING ||
                        workflowStatusMap?.[message.contextId || ""]?.status ===
                          WorkflowStatusEnum.RUNNING
                      }
                      isSuccess={
                        workflowStatusMap?.[message.contextId || ""]?.status ===
                        WorkflowStatusEnum.SUCCESS
                      }
                    />
                  )}
                  {message?.isDynamicContent &&
                    message?.dynamicInputType === DynamicInputType?.DATA && (
                      <DynamicInputDataToShow
                        data={message?.input_payload?.data_to_show}
                      />
                    )}
                  {message?.isDynamicContent &&
                    message?.dynamicInputType === DynamicInputType?.BUTTON && (
                      <DynamicInputButtons
                        isCompleted={
                          message?.input_id &&
                          completedInputIds?.includes(message?.input_id)
                            ? true
                            : false
                        }
                        data={message?.input_payload?.button}
                        onClick={sendDynamicInputMessage}
                        exec_id={message?.exec_id}
                        input_id={message?.input_id}
                        config={config?.config}
                      />
                    )}
                </Message.CustomContent>
              </Message>
            ))}
          </MessageList>

          <MessageInput
            sendButtonComponent={
              <img
                src={
                  config?.org_name &&
                  idToIconMap[config?.org_name?.toLowerCase()]
                    ? idToIconMap[config?.org_name?.toLowerCase()]
                    : SendMessageIcon
                }
                width={32}
                height={32}
                alt="send"
              />
            }
            attachButton={false}
            placeholder={"Write something here..."}
            disabled={botTyping}
            value={inputValue}
            onChange={setInputValue}
            onSend={(innerHtml, textConent, innerText) => {
              console.log("botTyping", botTyping);
              if (messages?.some((message) => message?.loading)) {
                return;
              }
              sendMessage(innerText);
            }}
          />
        </ChatContainer>

        <div
          className={`absolute border-2 top-0 bottom-0 left-0 right-0 ${
            showInputForm || openAuthScreen || openSettingsScreen
              ? "visible z-10"
              : "z-0 invisible"
          }`}
        >
          <ChatContainer>
            <ConversationHeader>
              <ConversationHeader.Back
                className="form-back"
                onClick={() => {
                  if (openSettingsScreen) {
                    setOpenSettingsScreen(false);
                    return;
                  }
                  setShowInputForm(false);
                }}
              ></ConversationHeader.Back>
              {/* <Avatar src={config?.profile_picture || "/chatbubble.png"} /> */}
              <ConversationHeader.Content
                userName={
                  openSettingsScreen ? "Copilot Settings" : "Running Automation"
                }
                info={messages[messages?.length - 1]?.workflow?.workflow?.title}
              />

              <ConversationHeader.Actions>
                {/* {showRefresh && <RefreshButton onClick={clearChat} />}
              {showClose && onClose && <CloseButton onClick={onClose} />} */}
                {/* <IconButton>
                <MoreVerticalIcon />
              </IconButton> */}
              </ConversationHeader.Actions>
            </ConversationHeader>
            <MessageList>
              {showInputForm && (
                <AutomationInputForm
                  showAskSession={!authComplete}
                  askSessionProps={{
                    onClick: () => {
                      captureData();
                    },
                    success: sessionDataSuccess,
                    error: sessionDataError,
                    loading: sessionDataLoading,
                  }}
                  rephraseResponse={rephrasedValues}
                  onRephrase={(values) => {
                    if (websocket && user && id) {
                      let user_id =
                        user?.user_id || localStorage?.getItem("user_id") || "";
                      let m_id = generateRandomString(8);
                      let chatId = sessionStorage.getItem("rbyln-chatId");
                      setUserId(user_id, false);
                      sendMessageToWs({
                        org_id: id,
                        m_id: m_id,
                        user_id: user_id,
                        event_type: "generate_content",
                        chat_id: chatId || "",
                        // message: e
                        user_inputs: values,
                      });
                    }
                  }}
                  config={config?.config}
                  onCancel={() => setShowInputForm(false)}
                  workflowMsg={inputMessage?.text || ""}
                  inputs={inputMessage?.inputs || []}
                  onSubmit={(values) => {
                    console.log("Hererere", values, user, id);
                    if (websocket && user && id) {
                      let user_id =
                        user?.user_id || localStorage?.getItem("user_id") || "";
                      let m_id = generateRandomString(8);
                      let chatId = sessionStorage.getItem("rbyln-chatId");
                      setUserId(user_id, false);

                      setBotTyping(true);

                      findAndUpdateWorkflowStatus(
                        inputMessage?.contextId || "",
                        WorkflowStatusEnum?.PREPARING
                      );

                      sendMessageToWs({
                        org_id: id,
                        m_id: m_id,
                        user_id: user_id,
                        event_type: "execute",
                        chat_id: chatId || "",
                        // message: e
                        user_inputs: values,
                      });
                      setShowInputForm(false);
                    }
                  }}
                />
              )}
              {openAuthScreen && (
                <AskSessionStandalone
                  askSessionProps={{
                    onClick: async () => {
                      await captureData();
                      // onRun(messages[messages?.length - 1]);
                    },
                    success: sessionDataSuccess,
                    error: sessionDataError,
                    loading: sessionDataLoading,
                    backgroundColor: config?.config?.brand_colour,
                  }}
                />
              )}
              {openSettingsScreen && (
                <SettingsPage
                  askSessionProps={{
                    onClick: async () => {
                      await captureData();
                    },
                    success: sessionDataSuccess,
                    error: sessionDataError,
                    loading: sessionDataLoading,
                    backgroundColor: config?.config?.brand_colour,
                  }}
                />
              )}
            </MessageList>
          </ChatContainer>
        </div>
      </MainContainer>
    </div>
  );
};

export default ChatbotUIClient;
