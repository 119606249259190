import React, { useState } from "react";
import CheckGreen from "assets/images/check-green.svg";
import Warning from "assets/images/warning-icon.svg";
import CaretRight from "assets/images/caret-right.svg";

interface JiraFormProps {
  workflowMsg: string;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  isCancelled?: boolean;
  onRun: () => void;
  onCancel: () => void;
  videoLink?: string;
  streamLink?: string;
  end_state_info?: {
    url: string;
    button_name: string;
  };
  config?: any;
  isDisabled?: boolean;
}

const ExecutionState: React.FC<JiraFormProps> = ({
  workflowMsg,
  isLoading,
  isSuccess,
  isError,
  isCancelled,
  onRun,
  onCancel,
  videoLink,
  streamLink,
  end_state_info,
  config,
  isDisabled,
}) => {
  console.log("end_state_info", end_state_info);
  const [taskSummary, setTaskSummary] = useState("");
  const [touched, setTouched] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  return (
    <div className="p-2 border border-robylon-gray rounded-md shadow-md w-[280px] relative">
      {isDisabled && (
        <div className="absolute left-0 right-0 top-0 bottom-0 bg-white opacity-50"></div>
      )}
      <div
        className={`font-semibold text-sm ${
          isCancelled ? "text-black" : "text-black"
        }`}
      >
        Automation
      </div>
      <div
        className={`border-b border-b-robylon-gray pt-1 pb-2 mb-2 ${
          isCancelled ? "text-black" : "text-black"
        }`}
      >
        {workflowMsg}
      </div>
      {!isDisabled && (
        <>
          {isError && (
            <div className="flex flex-row justify-between">
              <div className="flex flex-row items-center gap-1">
                <img src={Warning} />
                <div className="text-robylon-error text-base font-semibold">
                  Error Occurred
                </div>
              </div>
              {!!videoLink && (
                <button
                  style={{
                    borderColor: config?.brand_colour || "",
                    color: config?.brand_colour || "",
                  }}
                  className={`border ${
                    !config?.brand_colour
                      ? "text-robylon-blue border-robylon-blue "
                      : ""
                  } border-1 rounded px-2 py-0.5  font-semibold flex flex-row  items-center gap-1`}
                  onClick={() => {
                    window.open(videoLink);
                  }}
                >
                  <div>Video</div>
                  <img src={CaretRight} className="mt-0.5" />
                </button>
              )}
            </div>
          )}
          {isSuccess && (
            <div className="flex flex-row justify-between">
              <div className="flex flex-row items-center gap-1">
                <img src={CheckGreen} />
                <div className="text-green-500 font-semibold text-base">
                  Successful!
                </div>
              </div>
              <div>
                {!!videoLink && (
                  <button
                    style={{
                      borderColor: config?.brand_colour || "",
                      color: config?.brand_colour || "",
                    }}
                    className={`border ${
                      !config?.brand_colour
                        ? "text-robylon-blue border-robylon-blue "
                        : ""
                    } border-1 rounded px-2 py-0.5   font-semibold flex flex-row  items-center gap-1`}
                    onClick={() => {
                      window.open(videoLink);
                    }}
                  >
                    <div>Video</div>
                    <img src={CaretRight} className="mt-0.5" />
                  </button>
                )}
                {!!end_state_info && (
                  <button
                    style={{
                      borderColor: config?.brand_colour || "",
                      color: config?.brand_colour || "",
                    }}
                    className={`border ${
                      !config?.brand_colour
                        ? "text-robylon-blue border-robylon-blue "
                        : ""
                    } border-1 rounded px-2 py-0.5  font-semibold flex flex-row  items-center gap-1 mt-1`}
                    onClick={() => {
                      window.open(end_state_info?.url);
                    }}
                  >
                    <div>{end_state_info?.button_name}</div>
                    <img src={CaretRight} className="mt-0.5" />
                  </button>
                )}
              </div>
            </div>
          )}
          {!isSuccess && !isError && !isLoading && !isCancelled && (
            <div className="flex flex-row gap-2">
              <button
                onClick={onCancel}
                style={{
                  borderColor: config?.brand_colour || "",
                  color: config?.brand_colour || "",
                }}
                className={`py-2 px-3 ${
                  !config?.brand_colour
                    ? "text-robylon-blue border-robylon-blue"
                    : ""
                } bg-white border font-semibold rounded-md ${
                  isLoading && "opacity-50 cursor-not-allowed"
                }`}
                disabled={isLoading}
              >
                Cancel
              </button>
              <button
                onClick={onRun}
                style={{
                  borderColor: config?.brand_colour || "",
                  backgroundColor: config?.brand_colour || "",
                }}
                className={`py-2 px-3 text-white ${
                  !config?.brand_colour
                    ? " border-robylon-blue bg-robylon-blue"
                    : ""
                } rounded-md font-semibold border text-white ${
                  isLoading && "opacity-50 cursor-not-allowed"
                }`}
                // disabled={isLoading || !taskSummary}
              >
                {isLoading ? "In progress..." : "Run"}
              </button>
            </div>
          )}
          {isLoading && (
            <div className="flex flex-row justify-between">
              <div className="text-robylon-gray-dark">In progress...</div>
              {!!streamLink && (
                <button
                  style={{
                    borderColor: config?.brand_colour || "",
                    color: config?.brand_colour || "",
                  }}
                  className={`border ${
                    !config?.brand_colour
                      ? "text-robylon-blue border-robylon-blue "
                      : ""
                  } border-1 rounded px-2 py-0.5   font-semibold flex flex-row items-center gap-1`}
                  onClick={() => window.open(streamLink)}
                >
                  <div>Live</div>
                  <img src={CaretRight} className="mt-0.5" />
                </button>
              )}
            </div>
          )}
          {isCancelled && <div className="text-robylon-gray">Cancelled</div>}
        </>
      )}
      {isDisabled && (
        <>
          <div className="text-robylon-gray-dark">Paused</div>
        </>
      )}
    </div>
  );
};

export default ExecutionState;
